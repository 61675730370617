<template>
  <KTCard :page="page">
    <template #body>
      <p>
        <span class="font-weight-bold">Informações do ano: </span> {{ ano }}
      </p>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="bg-primary text-white">
            <tr class="text-center">
              <th rowspan="2">Item</th>
              <th colspan="2" v-for="mes in 12" :key="'th-1-' + mes">
                Mês {{ mes }}
              </th>
            </tr>
            <tr class="text-center">
              <template v-for="mes in 12">
                <th :key="'th-2-' + mes">{{ simbolo_moeda }}</th>
                <th :key="'th-3-' + mes">%</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in dre"
              :key="'tr-1-' + index"
              :class="{
                'bg-secondary': item.natureza == 1,
                'font-weight-bold': item.natureza == 1 || item.natureza == 2
              }"
            >
              <td
                :class="{
                  'pl-7': item.natureza == 3,
                  'pl-9': item.natureza == 4
                }"
              >
                {{ item.descricao }}
              </td>

              <template v-for="mes in 12">
                <td :key="`td-1-${index}-${mes}`" class="text-right">
                  {{ formatCurrency(item["valor_mes_" + mes]) }}
                </td>
                <td :key="`td-2-${index}-${mes}`" class="text-right">
                  {{ formatNumber(item["percentual_mes_" + mes]) }}
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipDreDetailList",
  props: {
    idplano_investimento: {
      required: true
    },
    ano: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-file-invoice-dollar",
        title: "Demonstrativo de Resultados do Exercício - DRE",
        description: `Aqui você pode verificar de forma detalhada o DRE mensal.
          Aproveite para analisar, revisar e identificar alguma incoerência. Se
          for necessário realizar alguma correção, retorne às etapas anteriores.`
      },
      dre: [],
      simbolo_moeda: null,
      tipo_plano_investimento: null
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/dre/detail/${this.ano}`;
    },
    manual() {
      return {
        session: "dre",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_dre_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.dre = res[R_GETLIST].dre;
        this.simbolo_moeda = res[R_GETLIST].simbolo_moeda;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
